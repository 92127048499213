import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import extend from 'quasar/src/utils/extend.js';
import user from '@/store/user';
import eventbus from '@/store/eventbus';
import {URL_API} from '@/config';
import {getModule} from 'vuex-module-decorators';
import {TUser} from '@/types/TUser';
import {TUserForAdmin} from '@/types/TUserForAdmin';
import {ServiceAdminUsers} from '@/services/ServiceAdminUsers';
import {CONFIG_ENV} from '@/config';
import UserProfile from '@/components/Admin/UserProfile/UserProfile.vue'
import EditNewUser from '@/components/Admin/EditNewUser/EditNewUser.vue'
import {ServiceAdminCompanies} from "@/services/ServiceAdminCompanies";
import {TCompany} from "@/types/TCompany";
import { l } from '@unhead/vue/dist/createHead-26c9c4af';
import { TSalesTeams } from '@/types/TSalesTeams';
import nomenclatoare from '@/store/nomenclatoare';
import axios, { AxiosResponse } from 'axios';


@Options({
    name: "Users",
    components: {UserProfile, EditNewUser}
})
export default class Users extends Vue {
    //@Prop({ default: '0' }) public readonly userid!: string;
    @Prop({ default: '0' }) public readonly companyAppid!: string;
    public users:TUserForAdmin[] = [];
    public loadingData = true;
    public isAdminUsersActivated=false;
    public dialogUser = false;
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public urlUserImgProfileByAppid = CONFIG_ENV.URL_ADMIN.users+'/img_user_profile_jpg_by_user_appid'
    public filter = '';
    public titleComponent='';
    declare public $refs: any;
    public currentPage=1;
    public dynamicComponent='';
    public totalNumberOfPages=1;
    public company:TCompany={    cif: '', name: '', sales_team: ''}
    public selectedUser:TUserForAdmin={
        appid: '',
        userid: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNr: '',
        isDisabled: '',
        companyName: '',
        userToken: '',
        urlUserImgProfileByAppid:'',
        hasAvatar:'',
        tip: 'client',
        agentId:'',
        id_team:'',
        agent_name: ''
    }
    public userStore = getModule(user);
    public EventBusStore = getModule(eventbus);
    public storeNomenclatoare = getModule(nomenclatoare);
    public myPagination:any={rowsPerPage:10}
    public visibleColumns: string[] = [ 'userid', 'firstName', 'lastName', 'phoneNr', 'emailAddress', 'isDisabled', 'companyName', 'type', 'delete', 'agent_name', 'id_team'];
    public  columns: any = [];
    public filterText = '';
    public searchAgent = '';
    public usersFiltered:TUserForAdmin[] = [];
    public downloadingXLS = false;

    public get user(): TUser {
        return this.userStore.user;
    }

    public get salesTeams(): TSalesTeams[] {
        return this.storeNomenclatoare.nomSalesTeams;
    }

    public resetPassword(){
        ServiceAdminUsers.resetPassword('abc')
    }

    public filterUsers(){
        const vueInst = this;
        vueInst.usersFiltered = vueInst.users.filter(user => user.agent_name.toLowerCase().includes(vueInst.searchAgent.toLowerCase()));
    }

    async downloadXLS(sheetName: string){
        const vueInst = this;
        vueInst.downloadingXLS=true;
        const params = new URLSearchParams();
        params.set('sheetName', sheetName);
        params.set('userid', this.$t('message.user_id'));
        params.set('first_name', this.$t('message.first_name'));
        params.set('last_name', this.$t('message.last_name'));
        params.set('company', this.$t('message.company'));
        params.set('agent', this.$t('message.agent2'));
        params.set('type', this.$t('message.type'));
        params.set('email', this.$t('message.email'));
        params.set('phone', this.$t('message.phone'));
        params.set('sales_team_id', this.$t('message.sales_team_id'));
        params.set('locale', this.$i18n.locale == 'ro' ? 'ro' : this.$i18n.locale == 'en' ? 'en-US' : 'hu');
        await axios.post(`${CONFIG_ENV.URL_DOWNLOAD.exportExcellUsers}`, params,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/vnd.ms-excel'
                },
                responseType: 'blob'
            }).then((response: AxiosResponse) => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const objectUrl = window.URL.createObjectURL(blob)
            //window.open(objectUrl)
            const link = document.createElement('a');
            link.href = objectUrl;
            // const fileName = (((response.headers as AxiosResponseHeaders)!['content-disposition'] as string)?.split(';')?.find(n => n.includes('filename=')) as string)?.replace('filename=', '')?.replaceAll('"', '')?.trim();
            link.setAttribute('download', sheetName + '.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            vueInst.downloadingXLS=false;
        }).catch((error: any) => { alert(error) })
    }

    public openUserDetails(userappid:string){
        //this.selectedUser=puser
       // extend(true, this.selectedUser,  puser);
        // this.titleComponent='Administrare utilizator';
        this.selectedUser.appid=userappid;
        this.titleComponent=this.$t('message.edit_account');
        this.dynamicComponent='EditNewUser';
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-left';
            this.dialogTransitionHide ='slide-right';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.dialogUser=true;
        this.dialogUser=true;
    }

    public getType(val: string){
        switch (val) {
            case "1" : return this.$t('message.admin');
            case "2" : return this.$t('message.agent');
            case "3" : return this.$t('message.agent_sef');
            case "4" : return this.$t('message.client');
        } 
    }

    public getTeam(id_team: string){
        const team = this.salesTeams.find((team) => team.id_team == id_team);
        // console.log(this.salesTeams);
        if(team != undefined) {
            return team.team_name;
        }
        return '';
    }

    public openFormAddNewUser(){
        this.selectedUser.appid='0';
        this.titleComponent=this.$t('message.new_account');
        this.dynamicComponent='EditNewUser';
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-left';
            this.dialogTransitionHide ='slide-right';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.dialogUser=true;
    }

    public fnOnSaveData(userappid:string){
        console.log('fnOnSaveData with userid=%o',userappid);
        this.dialogUser=false;
        // this.openUserDetails(userappid);
        this.filterText = '';
        this.getUsers();
    }

    public getUsers(){
        const vueInst = this;
        vueInst.loadingData=true;
        vueInst.users=[];
        ServiceAdminUsers.getUsersBackOffice(vueInst.filterText).then(response=>{
            vueInst.loadingData=false;
            if(response.status=='success'){
                vueInst.users=response.users.map(user=> {return {
                    appid: user.appid,
                    userid: user.userid,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    emailAddress: user.emailAddress,
                    phoneNr: user.phoneNr,
                    isDisabled: user.isDisabled,
                    companyName: user.companyName,
                    userToken: user.userToken,
                    hasAvatar: user.hasAvatar,
                    urlUserImgProfileByAppid:vueInst.urlUserImgProfileByAppid+'/'+user.appid,
                    tip: user.tip,
                    agentId:user.agentId,
                    id_team:user.id_team,
                    agent_name: user.agent_name
                }});
                vueInst.usersFiltered = vueInst.users;
            }
        })
    }

    public getCompany(){
        const vueInst=this;
        ServiceAdminCompanies.getCompanyByCif(vueInst.companyAppid).then(response=>{
            if(response.status=='success'){
                vueInst.company.cif=response.company.cif;
                vueInst.company.name=response.company.denumire;
                vueInst.getUsers();
            }
        });
    }

    public activated(){
        this.isAdminUsersActivated=true;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.users'));
        }
    }

    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        if(vueInst.EventBusStore.event.name=='changeLanguage'){
            vueInst.columns = [
                { name: 'appid', label: '', field: 'appid', align: 'left',  classes: 'bg-grey-1'},
                { name: 'userid', label: this.$t('message.user_id'), field: 'userid', align: 'left', sortable: true, classes: 'bg-grey-1'},
                { name: 'firstName', label: this.$t('message.first_name'), field: 'firstName', align: 'left', sortable: true  },
                { name: 'lastName', label: this.$t('message.last_name'), field: 'lastName', align: 'left', sortable: true },
                { name: 'phoneNr', label: this.$t('message.phone'), field: 'phoneNr', align: 'left', sortable: true },
                { name: 'emailAddress', label: this.$t('message.email'), field: 'emailAddress', align: 'left', sortable: true },
                { name: 'type', label: this.$t('message.type'), field: 'type', align: 'left', sortable: true },
                { name: 'isDisabled', label: this.$t('message.blocked_user'), field: 'isDisabled', align: 'left'},
                { name: 'companyName', label: this.$t('message.company'), field: 'companyName', align: 'left', sortable: true },
                { name: 'id_team', label: this.$t('message.sales_team_id'), field: 'id_team', align: 'left', sortable: true },
                { name: 'agent_name',  label: this.$t('message.agent2'), field: 'agent_name', align: 'left', sortable: true},
                { name: 'delete', label: this.$t('message.delete'), field: 'delete', align: 'left'},
            ];
        }
    }

    @Watch('companyAppid', { immediate: true, deep: false })
    onCompanyAppidChanged() {
        const vueInst=this;
        if(vueInst.companyAppid!='0'){
            vueInst.getCompany();
        }

    }

    changeStatus(user: TUserForAdmin){
        const vueInst=this;
        ServiceAdminUsers.changeStatusUser(user.userid,user.isDisabled).then(response=>{
            vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                icon: 'positive',
                position: 'top',
                timeout: 2000,
                message: response.message
            })
            
        })
    }
    public customHungarianSort(a: string, b: string) {
        console.log('Sorting:', a, b);
        return a.localeCompare(b, 'hu');
    }

    

    public deleteUser(user: TUserForAdmin) {
        const vueInst = this;
        vueInst.$q.dialog({
            title: this.$t('message.delete_user'),
            message: ` ${user.firstName} ${user.lastName}`,
            ok: {
                flat:true,
                label: this.$t('message.delete'),
            },
            cancel: {
                label: this.$t('message.drop'),
                flat:true
            },
            persistent: true
        }).onOk(() => {
            ServiceAdminUsers.deleteUser(user.userid).then(response=>{
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    icon: 'positive',
                    position: 'top',
                    timeout: 2000,
                    message: response.message
                });
                vueInst.getUsers();
            })
        })
        
    }

    public created(): void {
        const vueInst = this;
        vueInst.columns = [
            { name: 'appid', label: '', field: 'appid', align: 'left',  classes: 'bg-grey-1'},
            { name: 'userid', label: this.$t('message.user_id'), field: 'userid', align: 'left', sortable: true, classes: 'bg-grey-1'},
            { name: 'firstName', label: this.$t('message.first_name'), field: 'firstName', align: 'left', sortable: true  },
            { name: 'lastName', label: this.$t('message.last_name'), field: 'lastName', align: 'left', sortable: true },
            { name: 'phoneNr', label: this.$t('message.phone'), field: 'phoneNr', align: 'left', sortable: true },
            { name: 'emailAddress', label: this.$t('message.email'), field: 'emailAddress', align: 'left', sortable: true },
            { name: 'type', label: this.$t('message.type'), field: 'type', align: 'left', sortable: true },
            { name: 'isDisabled', label: this.$t('message.blocked_user'), field: 'isDisabled', align: 'left'},
            { name: 'companyName', label: this.$t('message.company'), field: 'companyName', align: 'left', sortable: true },
            { name: 'id_team', label: this.$t('message.sales_team_id'), field: 'id_team', align: 'left', sortable: true },
            { name: 'agent_name',  label: this.$t('message.agent2'), field: 'agent_name', align: 'left', sortable: true},
            { name: 'delete', label: this.$t('message.delete'), field: 'delete', align: 'left'},
        ];
        vueInst.getUsers();
    }

    public deactivated() {
        this.isAdminUsersActivated = false;
    }

}
